import { db } from '../firebase';
import { collection, query, where, orderBy, limit as firestoreLimit, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';

const GAME_HISTORY_COLLECTION = 'gameHistory';
const CATEGORIES = ['population', 'area'];

export const gameHistoryService = {
  async getTopGames(userId, category, limitCount = 5) {
    try {
      const gamesRef = collection(db, GAME_HISTORY_COLLECTION);
      const q = query(
        gamesRef,
        where('userId', '==', userId),
        where('category', '==', category),
        orderBy('score', 'desc'),
        firestoreLimit(limitCount)
      );

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      // If the index is not ready, fall back to fetching all games and sorting in memory
      const q = query(
        collection(db, GAME_HISTORY_COLLECTION),
        where('userId', '==', userId),
        where('category', '==', category)
      );

      const querySnapshot = await getDocs(q);
      const games = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      return games.sort((a, b) => b.score - a.score).slice(0, limitCount);
    }
  },

  async getAllTopGames(userId) {
    try {
      const topGames = {};
      await Promise.all(
        CATEGORIES.map(async category => {
          topGames[category] = await this.getTopGames(userId, category);
        })
      );
      return topGames;
    } catch (error) {
      console.error('Error fetching all top games:', error);
      return {};
    }
  },

  async saveGame(userId, category, score, countries) {
    try {
      const minimalCountries = countries.map(({ id, name, flagUrl }) => ({
        id,
        name,
        flagUrl
      }));

      await addDoc(collection(db, GAME_HISTORY_COLLECTION), {
        userId,
        category,
        score,
        countries: minimalCountries,
        timestamp: serverTimestamp()
      });
    } catch (error) {
      console.error('Error saving game history:', error);
      throw error;
    }
  }
}; 