// src/pages/GamePage.js

import React, { useState, useEffect, useCallback } from 'react';
import CountryCard from '../components/CountryCard';
import './GamePage.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchCountries } from '../api/countriesApi';
import { achievementsService } from '../services/achievementsService';
import { useAuth } from '../contexts/AuthContext';

function GamePage() {
  const { currentUser } = useAuth();
  const [sortedCountries, setSortedCountries] = useState([]);
  const [currentCountry, setCurrentCountry] = useState(null);
  const [remainingCountries, setRemainingCountries] = useState([]);
  const [score, setScore] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [isCooperationMode, setIsCooperationMode] = useState(false);
  const [playerName, setPlayerName] = useState('');
  const [gameState, setGameState] = useState('waiting'); // waiting, playing, ended
  const navigate = useNavigate();
  const location = useLocation();

  // Helper function to get a random country from the array
  const getRandomCountry = (countries) => {
    const randomIndex = Math.floor(Math.random() * countries.length);
    return countries.splice(randomIndex, 1)[0];
  };

  const startNewGame = useCallback((data) => {
    const availableCountries = [...data];
    
    if (isCooperationMode) {
      // For cooperation mode:
      // 1. Select 10 countries to sort
      // 2. Pick one as the starting country
      // 3. Set up the remaining countries
      const selectedCountries = [];
      for (let i = 0; i < 10; i++) {
        if (availableCountries.length === 0) break;
        selectedCountries.push(getRandomCountry(availableCountries));
      }

      // Pick a random starting country from the selected ones
      const startingCountry = getRandomCountry(selectedCountries);
      
      setSortedCountries([startingCountry]);
      setRemainingCountries(selectedCountries);
      setScore(1);
      setCurrentCountry(null);
      setGameState('playing');

      // Immediately pick the next country to sort
      const countriesCopy = [...selectedCountries];
      const randomIndex = Math.floor(Math.random() * countriesCopy.length);
      const nextCountry = countriesCopy.splice(randomIndex, 1)[0];
      setCurrentCountry(nextCountry);
      setRemainingCountries(countriesCopy);
      return;
    }

    // For classic mode, pick a random starting country
    const firstCountry = getRandomCountry(availableCountries);
    const nextCountry = getRandomCountry(availableCountries);

    setSortedCountries([firstCountry]);
    setRemainingCountries(availableCountries);
    setScore(1);
    setCurrentCountry(nextCountry);
    setGameState('playing');
  }, [isCooperationMode]);

  useEffect(() => {
    const initializeGame = async () => {
      const path = location.pathname;
      setIsCooperationMode(path.includes('cooperation'));

      if (isCooperationMode) {
        const { players, lobbyId } = location.state || {};
        if (!players || !lobbyId) {
          navigate('/game/population/cooperation');
          return;
        }

        // Set player name
        const player = players.find(p => p.id === 'player1') ? 'player1' : 'player2';
        setPlayerName(players.find(p => p.id === player)?.name || player);
      }

      const data = await fetchCountries();
      const validCountries = data.filter(
        (country) => country.population && country.area && country.flagUrl
      );
      startNewGame(validCountries);
    };

    initializeGame();
  }, [startNewGame, location, navigate, isCooperationMode]);

  // Determine the comparison property based on the game mode
  const compareProperty = location.pathname.includes('area') ? 'area' : 'population';

  const pickNextCountry = async () => {
    if (remainingCountries.length === 0) {
      console.log('Game completed successfully!');
      console.log('Final score:', score);
      
      if (currentUser && !isCooperationMode) {
        try {
          const updatedAchievements = await achievementsService.checkAndUpdateAchievements(
            currentUser.uid,
            location.pathname.split('/')[2],
            score
          );
          console.log('Updated achievements:', updatedAchievements);
        } catch (error) {
          console.error('Error updating achievements:', error);
        }
      }

      navigate('/gameover', {
        state: { score, message: 'Congratulations! You sorted all countries correctly.' },
      });
      return;
    }

    const countriesCopy = [...remainingCountries];
    const randomIndex = Math.floor(Math.random() * countriesCopy.length);
    const nextCountry = countriesCopy.splice(randomIndex, 1)[0];

    setCurrentCountry(nextCountry);
    setRemainingCountries(countriesCopy);
  };

  const handleInsert = async (index) => {
    const newSortedCountries = [...sortedCountries];
    newSortedCountries.splice(index, 0, currentCountry);

    if (isCorrectOrder(newSortedCountries)) {
      setSortedCountries(newSortedCountries);
      setScore((prevScore) => prevScore + 1);
      setCurrentCountry(null);
      pickNextCountry();
    } else {
      const allCountries = [...sortedCountries, currentCountry];
      const correctOrder = [...allCountries].sort((a, b) => a[compareProperty] - b[compareProperty]);

      console.log('Game ended with incorrect placement');
      console.log('Final score:', score);

      if (currentUser && !isCooperationMode) {
        try {
          const updatedAchievements = await achievementsService.checkAndUpdateAchievements(
            currentUser.uid,
            location.pathname.split('/')[2],
            score
          );
          console.log('Updated achievements:', updatedAchievements);
        } catch (error) {
          console.error('Error updating achievements:', error);
        }
      }

      navigate('/gameover', {
        state: {
          score,
          message: isCooperationMode ? 'Game Over! One of the players made a mistake.' : 'Incorrect placement!',
          incorrectCountry: currentCountry,
          userOrder: newSortedCountries,
          correctOrder: correctOrder,
        },
      });
    }
  };

  const isCorrectOrder = (countriesList) => {
    for (let i = 0; i < countriesList.length - 1; i++) {
      if (countriesList[i][compareProperty] > countriesList[i + 1][compareProperty]) {
        return false;
      }
    }
    return true;
  };

  if (gameState === 'waiting') {
    return <div className="game-page">Loading...</div>;
  }

  return (
    <div className="game-page">
      <h2>
        {isCooperationMode ? 'Cooperation Mode' : 
         `Sort Countries by ${compareProperty === 'area' ? 'Area' : 'Population'} (Ascending)`}
      </h2>
      {isCooperationMode && (
        <p>Current Player: {playerName}</p>
      )}
      <p>Score: {score - 1}</p>
      <div className="sorted-countries-container">
        {currentCountry && (
          <div className="instructions">
            <p>
              Where does <strong>{currentCountry.name}</strong> fit among the sorted countries?
            </p>
          </div>
        )}
        <div className="sorted-countries">
          {sortedCountries.map((country, index) => (
            <CountryCard
              key={`country-${country.id}`}
              country={country}
              isClickable={true}
              mode={compareProperty}
              highlight={hoverIndex === index ? 'hover' : ''}
            />
          ))}
        </div>

        {currentCountry && (
          <div className="insert-buttons">
            <button
              className="insert-button lower-button"
              onClick={() => handleInsert(0)}
              onMouseEnter={() => setHoverIndex(0)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              ↓
            </button>
            {sortedCountries.map((_, index) => (
              <button
                key={`insert-${index + 1}`}
                className={`insert-button ${index === sortedCountries.length - 1 ? 'higher-button' : 'here-button'}`}
                onClick={() => handleInsert(index + 1)}
                onMouseEnter={() => setHoverIndex(index + 1)}
                onMouseLeave={() => setHoverIndex(null)}
              >
                {index === sortedCountries.length - 1 ? '↑' : index + 1}
              </button>
            ))}
          </div>
        )}
      </div>
      {currentCountry && (
        <div className="current-country">
          <h3>Current Country:</h3>
          <CountryCard country={currentCountry} isClickable={false} mode={compareProperty} />
        </div>
      )}
      {isCooperationMode && remainingCountries.length > 0 && (
        <div className="remaining-countries">
          <h3>Remaining Countries to Sort:</h3>
          <div className="remaining-countries-grid">
            {remainingCountries.map((country) => (
              <CountryCard
                key={`remaining-${country.id}`}
                country={country}
                isClickable={false}
                mode={compareProperty}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default GamePage;
