// src/api/countriesApi.js

export const fetchCountries = async () => {
  try {
    const response = await fetch('https://restcountries.com/v3.1/all');
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (!Array.isArray(data)) {
      throw new Error('Invalid data format received from API');
    }

    // Filter to only include UN member states with valid data
    const unMemberCountries = data.filter((country) => 
      country.unMember && 
      country.name?.common && 
      country.flags?.svg && 
      typeof country.population === 'number' && 
      typeof country.area === 'number'
    );

    // Map the API data to match your application's structure
    const countries = unMemberCountries.map((country, index) => ({
      id: index + 1,
      name: country.name.common,
      flagUrl: country.flags.svg,
      population: country.population,
      area: country.area,
    }));

    return countries;
  } catch (error) {
    console.error('Error fetching countries:', error);
    throw new Error('Failed to fetch countries data. Please try again later.');
  }
};
  