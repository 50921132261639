import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { userProfileService } from '../services/userProfileService';
import './GameLobby.css';

function GameLobby() {
  const { currentUser } = useAuth();
  const [lobbyId, setLobbyId] = useState('');
  const [players, setPlayers] = useState([]);
  const [isHost, setIsHost] = useState(false);
  const [error, setError] = useState('');
  const [copyStatus, setCopyStatus] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const initializeLobby = async () => {
      // Check for lobby ID in URL parameters
      const lobbyParam = searchParams.get('lobby');
      
      if (lobbyParam) {
        // Join existing lobby
        setLobbyId(lobbyParam);
        setIsHost(false);
        
        // Get current user's nickname if logged in
        let playerName = 'Player 2';
        if (currentUser) {
          try {
            const profile = await userProfileService.getUserProfile(currentUser.uid);
            if (profile?.nickname) {
              playerName = profile.nickname;
            }
          } catch (error) {
            console.error('Error fetching user profile:', error);
          }
        }
        
        setPlayers([{ id: 'player2', name: playerName }]);
      } else {
        // Create new lobby
        const newLobbyId = Math.random().toString(36).substring(2, 8).toUpperCase();
        setLobbyId(newLobbyId);
        setIsHost(true);
        
        // Get current user's nickname if logged in
        let playerName = 'Player 1';
        if (currentUser) {
          try {
            const profile = await userProfileService.getUserProfile(currentUser.uid);
            if (profile?.nickname) {
              playerName = profile.nickname;
            }
          } catch (error) {
            console.error('Error fetching user profile:', error);
          }
        }
        
        setPlayers([{ id: 'player1', name: playerName }]);
      }

      // Development mode: Add a second player automatically
      if (process.env.NODE_ENV === 'development') {
        setTimeout(() => {
          setPlayers(prev => [
            ...prev,
            { id: 'player2', name: 'Player 2 (Dev)' }
          ]);
        }, 1000);
      }
    };

    initializeLobby();
  }, [searchParams, currentUser]);

  const copyLink = async () => {
    // Use the custom domain directly
    const baseUrl = 'https://sortly.de';
    // Ensure the URL has proper query parameter format
    const gameLink = `${baseUrl}/#/game/population/cooperation?lobby=${encodeURIComponent(lobbyId)}`;
    
    try {
      // Try using the modern clipboard API first
      await navigator.clipboard.writeText(gameLink);
      setCopyStatus('Copied!');
      setTimeout(() => {
        setCopyStatus('');
      }, 2000);
    } catch (err) {
      // Fallback for older browsers
      const textArea = document.createElement('textarea');
      textArea.value = gameLink;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        setCopyStatus('Copied!');
        setTimeout(() => {
          setCopyStatus('');
        }, 2000);
      } catch (err) {
        setCopyStatus('Failed to copy');
        console.error('Failed to copy text: ', err);
      }
      document.body.removeChild(textArea);
    }
  };

  const startGame = () => {
    if (players.length < 2) {
      setError('Waiting for another player to join...');
      return;
    }
    navigate('/game/population/cooperation/play', { state: { lobbyId, players } });
  };

  return (
    <div className="game-lobby">
      <h2>Game Lobby</h2>
      <div className="lobby-info">
        <p>Lobby ID: <strong>{lobbyId}</strong></p>
        {isHost && (
          <button 
            className={`button button-secondary copy-button ${copyStatus ? 'copied' : ''}`}
            onClick={copyLink}
          >
            {copyStatus || 'Copy Invite Link'}
          </button>
        )}
      </div>

      <div className="players-list">
        <h3>Players in Lobby:</h3>
        <ul>
          {players.map((player) => (
            <li key={player.id}>{player.name}</li>
          ))}
        </ul>
      </div>

      {error && <p className="error">{error}</p>}

      <div className="lobby-actions">
        <button className="button button-primary" onClick={startGame}>
          Start Game
        </button>
        <button 
          className="button button-secondary" 
          onClick={() => navigate('/')}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default GameLobby; 