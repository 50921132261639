// src/App.js

import React, { useEffect } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Header from './components/Header';
import Footer from './components/Footer'; 
import HomePage from './pages/HomePage';
import GamePage from './pages/GamePage';
import GameOverPage from './pages/GameOverPage';
import LeaderboardPage from './pages/LeaderboardPage';
import ProfilePage from './pages/ProfilePage';
import GameLobby from './components/GameLobby';
import { AuthProvider } from './contexts/AuthContext';
import { FirebaseTest } from './components/FirebaseTest';
import './App.css';

function App() {
  const location = useLocation();

  // Initialize Google Analytics only once
  useEffect(() => {
    ReactGA.initialize('G-9679TPXEBR'); // Replace with your Measurement ID
  }, []);

  // Send pageview whenever the pathname changes
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location.pathname]);

  return (
    <AuthProvider>
      <div className="App">
        <Header />
        <div className="content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/game/population" element={<GamePage />} />
            <Route path="/game/population/cooperation" element={<GameLobby />} />
            <Route path="/game/population/cooperation/play" element={<GamePage />} />
            <Route path="/game/area" element={<GamePage />} />
            <Route path="/gameover" element={<GameOverPage />} />
            <Route path="/leaderboard" element={<LeaderboardPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/test" element={<FirebaseTest />} />
          </Routes>
        </div>
        <Footer /> 
      </div>
    </AuthProvider>
  );
}

export default App;
