// src/pages/HomePage.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4'; // Import ReactGA
import populationImage from '../assets/population.jpg';
import areaImage from '../assets/area.jpg';
import './HomePage.css';

function HomePage() {
  const navigate = useNavigate();

  // Define the handler functions
  const handlePlayPopulation = () => {
    // Track the event in Google Analytics
    ReactGA.event({
      category: 'Game',
      action: 'Clicked Play Population Game',
    });
    // Navigate to the population game page
    navigate('/game/population');
  };

  const handlePlayArea = () => {
    // Track the event in Google Analytics
    ReactGA.event({
      category: 'Game',
      action: 'Clicked Play Area Game',
    });
    // Navigate to the area game page
    navigate('/game/area');
  };

  const handlePlayCooperation = () => {
    ReactGA.event({
      category: 'Game',
      action: 'Clicked Play Cooperation Game',
    });
    navigate('/game/population/cooperation');
  };

  return (
    <div className="homepage">
      {/* Section 1: Sort by Population */}
      <div 
        className="section population-section"
        style={{
          backgroundImage: `url(${populationImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className="overlay">
          <h2>Sort by Population</h2>
          <div className="population-modes">
            <button
              className="button button-primary"
              onClick={handlePlayPopulation}
            >
              Classic Mode
            </button>
            <button
              className="button button-primary"
              onClick={handlePlayCooperation}
            >
              Cooperation Mode
            </button>
          </div>
        </div>
      </div>

      {/* Section 2: Sort by Area */}
      <div 
        className="section area-section"
        style={{
          backgroundImage: `url(${areaImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className="overlay">
          <h2>Sort by Area</h2>
          <button
            className="button button-primary"
            onClick={handlePlayArea}
          >
            Play Now
          </button>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
