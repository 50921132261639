export const avatarService = {
  getAvatarOptions() {
    return [
      {
        id: 'default',
        url: 'https://api.dicebear.com/7.x/avataaars/svg?seed=default',
        name: 'Default'
      },
      {
        id: 'happy',
        url: 'https://api.dicebear.com/7.x/avataaars/svg?seed=happy',
        name: 'Happy'
      },
      {
        id: 'cool',
        url: 'https://api.dicebear.com/7.x/avataaars/svg?seed=cool',
        name: 'Cool'
      },
      {
        id: 'smart',
        url: 'https://api.dicebear.com/7.x/avataaars/svg?seed=smart',
        name: 'Smart'
      },
      {
        id: 'friendly',
        url: 'https://api.dicebear.com/7.x/avataaars/svg?seed=friendly',
        name: 'Friendly'
      },
      {
        id: 'adventurous',
        url: 'https://api.dicebear.com/7.x/avataaars/svg?seed=adventurous',
        name: 'Adventurous'
      },
      {
        id: 'creative',
        url: 'https://api.dicebear.com/7.x/avataaars/svg?seed=creative',
        name: 'Creative'
      },
      {
        id: 'mysterious',
        url: 'https://api.dicebear.com/7.x/avataaars/svg?seed=mysterious',
        name: 'Mysterious'
      }
    ];
  }
}; 