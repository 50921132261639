// src/pages/GameOverPage.js

import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CountryCard from '../components/CountryCard';
import { submitScore } from '../api/leaderboardApi';
import { gameHistoryService } from '../services/gameHistoryService';
import { userProfileService } from '../services/userProfileService';
import { useAuth } from '../contexts/AuthContext';
import './GameOverPage.css';
import '../components/Buttons.css';

function GameOverPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { score, message, incorrectCountry, userOrder, correctOrder, mode } = location.state || {};
  const [playerName, setPlayerName] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const hasSaved = useRef(false);

  // Fetch user profile and set nickname if logged in
  useEffect(() => {
    const fetchUserProfile = async () => {
      if (currentUser) {
        try {
          const profile = await userProfileService.getUserProfile(currentUser.uid);
          if (profile?.nickname) {
            setPlayerName(profile.nickname);
          }
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      }
    };
    fetchUserProfile();
  }, [currentUser]);

  useEffect(() => {
    const saveGameHistory = async () => {
      if (currentUser && userOrder && !hasSaved.current) {
        try {
          console.log('Attempting to save game history:', {
            userId: currentUser.uid,
            mode,
            score: score - 1,
            countriesCount: userOrder.length
          });
          
          await gameHistoryService.saveGame(
            currentUser.uid,
            mode,
            score - 1,
            userOrder.map(country => ({
              id: country.id,
              name: country.name,
              flagUrl: country.flagUrl
            }))
          );
          hasSaved.current = true;
          console.log('Game history saved successfully');
        } catch (error) {
          console.error('Error saving game history:', error);
        }
      }
    };

    saveGameHistory();
  }, [currentUser, userOrder, mode, score]); // Include all dependencies

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!playerName.trim()) {
      setError('Please enter your name');
      return;
    }
    
    try {
      submitScore(playerName, score - 1, mode);
      setIsSubmitted(true);
    } catch (err) {
      setError('Failed to submit score. Please try again.');
    }
  };

  return (
    <div className="game-over-page">
      <h2>Game Over</h2>
      <p>{message}</p>
      <p>Your final score: {score - 1}</p>

      {!isSubmitted ? (
        <form onSubmit={handleSubmit} className="score-submission">
          <div className="input-group">
            <label htmlFor="playerName">Enter your name:</label>
            <input
              type="text"
              id="playerName"
              value={playerName}
              onChange={(e) => setPlayerName(e.target.value)}
              placeholder="Your name"
              maxLength={20}
            />
          </div>
          {error && <p className="error">{error}</p>}
          <button type="submit" className="button button-primary">
            Submit Score
          </button>
        </form>
      ) : (
        <div className="submission-success">
          <p>Score submitted successfully!</p>
        </div>
      )}

      {userOrder && incorrectCountry && (
        <div className="user-order">
          <h3>Your order was:</h3>
          <div className="country-list">
            {userOrder.map((country) => (
              <CountryCard
                key={country.id}
                country={country}
                isClickable={true}
                highlight={country.id === incorrectCountry.id ? 'incorrect' : ''}
                mode={mode}
              />
            ))}
          </div>
        </div>
      )}

      {correctOrder && incorrectCountry && (
        <div className="correct-order">
          <h3>The correct order was:</h3>
          <div className="country-list">
            {correctOrder.map((country) => (
              <CountryCard
                key={country.id}
                country={country}
                isClickable={true}
                highlight={country.id === incorrectCountry.id ? 'correct' : ''}
                mode={mode}
              />
            ))}
          </div>
        </div>
      )}

      <div className="game-over-buttons">
        <button className="button button-primary" onClick={() => navigate(`/game/${mode}`)}>
          Play Again
        </button>
        <button className="button button-secondary" onClick={() => navigate('/')}>
          Go to Home Page
        </button>
        <button className="button button-secondary" onClick={() => navigate('/leaderboard')}>
          View Leaderboard
        </button>
      </div>
    </div>
  );
}

export default GameOverPage;
